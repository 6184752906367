import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/bestpractice/bestpractice/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "snowplows"
    }}>{`Snowplows`}</h1>
    <p>{`The snowplow API allows querying the real-time and historical locations of snowplows in the Helsinki area.`}</p>
    <p>{`The snowplow API allows querying the locations of the snowplows in the Helsinki area. Check out the `}<a parentName="p" {...{
        "href": "http://auratkartalla.com/"
      }}>{`Aurat kartalla application`}</a>{` and its `}<a parentName="p" {...{
        "href": "https://github.com/sampsakuronen/snowplow-visualization"
      }}>{`source`}</a>{`.`}</p>
    <ul>
      <li parentName="ul">{`API location: `}<a parentName="li" {...{
          "href": "https://dev.hel.fi/aura/v1/snowplow/"
        }}>{`dev.hel.fi/aura/v1/snowplow/`}</a></li>
    </ul>
    <h1 {...{
      "id": "documentation"
    }}>{`Documentation`}</h1>
    <p>{`Example query result:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`    {
        "id": "5407",
        "last_location": {
            "timestamp": "2013-02-19T10:37:33",
            "coords": [
                24.95477988702919,
                60.1847431874767
            ],
            "events": [
                "au"
            ]
        }
    },
`}</code></pre>
    <p>{`The field `}<inlineCode parentName="p">{`last_location`}</inlineCode>{` is the plow's last recorded location with a timestamp. `}<inlineCode parentName="p">{`last_location`}</inlineCode>{` is updated as new samples are received.`}</p>
    <p><inlineCode parentName="p">{`events`}</inlineCode>{` describes what kind of activity the plow was performing at that location. Values include:`}</p>
    <dl>
  <dt>kv</dt><dd>Bicycle and pedestrian lanes</dd>
  <dt>au</dt><dd>Snow removal (auraus)</dd>
  <dt>su</dt><dd>De-icing with salt (suolaus)</dd>
  <dt>hi</dt><dd>Spreading sand (hiekoitus)</dd>
  <dt>nt</dt><dd>Mowing (niitto)</dd>
  <dt>ln</dt><dd>"Lanaus"</dd>
  <dt>hs</dt><dd>Planing (höyläys)</dd>
  <dt>pe</dt><dd>Street washing (kadunpesu)</dd>
  <dt>ps</dt><dd>"Pölynsidonta"</dd>
  <dt>hn</dt><dd>Sand removal (hiekannosto)</dd>
  <dt>hj</dt><dd>Brushing (harjaus)</dd>
  <dt>pn</dt><dd>Coating (pinnoitus, liittyy kesähoitoreitteihin</dd>
    </dl>
    <p>{`In order to retrieve detailed information for a single plow, the URL is formed like this:`}</p>
    <pre><code parentName="pre" {...{}}>{`http://dev.hel.fi/aura/v1/snowplow/<id>
`}</code></pre>
    <h2 {...{
      "id": "query-parameters"
    }}>{`Query parameters`}</h2>
    <p>{`You can provide parameters to filter the query or add more information to the response. Parameters include:`}</p>
    <pre><code parentName="pre" {...{}}>{`history=<int>
`}</code></pre>
    <p>{`Response will a include a list of `}<inlineCode parentName="p">{`<int>`}</inlineCode>{` points of historical location data for the plow.`}</p>
    <pre><code parentName="pre" {...{}}>{`since=<time>
`}</code></pre>
    <p>{`Show only plows that have been active since `}<inlineCode parentName="p">{`<time>`}</inlineCode>{`. `}<inlineCode parentName="p">{`<time>`}</inlineCode>{` can be a timestamp or a relative time. When querying details of a single plow, will return historical locations since `}<inlineCode parentName="p">{`<time>`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`limit=<int>
`}</code></pre>
    <p>{`Return at most `}<inlineCode parentName="p">{`<int>`}</inlineCode>{` plows.`}</p>
    <pre><code parentName="pre" {...{}}>{`temporal_resolution=<int>
`}</code></pre>
    <p>{`When querying individual plow details, return historical locations that must be at least `}<inlineCode parentName="p">{`<int>`}</inlineCode>{` seconds apart.`}</p>
    <h3 {...{
      "id": "examples"
    }}>{`Examples`}</h3>
    <pre><code parentName="pre" {...{}}>{`http://dev.hel.fi/aura/v1/snowplow/5407?history=50
`}</code></pre>
    <p>{`The query above will display information about plow `}<inlineCode parentName="p">{`5407`}</inlineCode>{` with 50 previous locations included.`}</p>
    <pre><code parentName="pre" {...{}}>{`http://dev.hel.fi/aura/v1/snowplow/?since=2hours+ago&limit=10
`}</code></pre>
    <p>{`This will return at most 10 plow routes that were active 2 hours ago or more recently.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      